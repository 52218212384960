import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackgroundSvg1 from "../components/BackgroundImages/backgroundSvg1";
import Rankit from "../Images/Rankit-Logo.png";
import Navbar from "../components/Navbar";
import { Trans, useTranslation } from "react-i18next";
import { config_data } from "../config.js";
import { useCookies } from "react-cookie";

const EnCookiePolicy = () => {
  const { t } = useTranslation();

  return (
    <div class="dark">
      <Navbar />
      <section class="signIn my-5 py-5">
        <div class="container my-3">
          <div class="row align-items-center">
            <div class="col-lg-12 picture" style={{ color: "white" }}>
              <h3 class="title my-5" align="center">
                Cookie Policy
              </h3>

              <p class="paragraph-44">
                HTTP cookies (more commonly called web cookies, or par
                excellence cookies) are a particular type of cookie, a sort of
                identification token, used by web servers to recognize browsers
                during communications with the HTTP protocol used for web
                browsing. http://www.rankit.it or the "Site" uses only technical
                cookies that do not intend consent.
              </p>
              {/*<ul role="list" class="list-3">
          <li>memorizzare le preferenze inserite</li>
          <li>evitare di reinserire le stesse informazioni più volte durante la visita quali ad esempio nome utente e password</li>
          <li>analizzare l’utilizzo dei servizi e dei contenuti forniti da http://www.rankit.it per ottimizzarne l’esperienza di navigazione e i servizi offerti<br/></li>
        </ul>*/}
              {/*<h1 class="heading-69"><strong class="bold-text-2">Tipologie di Cookie utilizzati da http://www.rankit.it</strong><br/></h1>
        <p class="paragraph-42">A seguire i vari tipi di cookie utilizzati da http://www.rankit.it in funzione delle finalità d’uso</p>*/}
              <h4>
                <strong>Technical Cokiees</strong>
              </h4>
              <p class="paragraph-42">
                These types of cookies allows the correct functioning of some
                sections of the Website.{" "}
                {/*Sono di due categorie: persistenti e di sessione:*/}
              </p>
              <ul role="list" class="list-4">
                {/*  <li><strong>persistenti</strong>: una volta chiuso il browser non vengono distrutti ma rimangono fino ad una data di scadenza preimpostata</li>*/}
                <li>
                  <strong>of sessions</strong>: they are destroyed every time
                  the browser is closed
                </li>
              </ul>
              <p class="paragraph-43">
                These cookies, always sent by our domain, are necessary to
                correctly view the website and in relation to the technical
                services offered, they will therefore always be used and sent,
                unless the user does not change the settings in his browser
                (thus affecting the display of the pages of the website).
              </p>
              {/*  <h1 class="heading-71"><strong class="bold-text-2">Cookie analitici<br/></strong><br/></h1>
        <p class="paragraph-43">I cookie in questa categoria vengono utilizzati per <strong>collezionare informazioni sull’uso del sito</strong>. http://www.rankit.it userà queste informazioni in merito ad <strong>analisi statistiche anonime</strong> al fine di migliorare l’utilizzo del Sito e per rendere i contenuti più interessanti e attinenti ai desideri dell’utenza. Questa tipologia di cookie raccoglie dati in <strong>forma anonima</strong> sull’attività dell’utenza e su come è arrivata sul Sito. I cookie analitici sono inviati dal Sito Stesso o da domini di terze parti.<br/></p>

        <h1 class="heading-72"><strong class="bold-text-2">Cookie di profilazione<br/></strong><br/></h1>
        <p class="paragraph-43">Sono quei cookie necessari a creare profili utenti al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dall’utente all’interno delle pagine del Sito.<br/></p>
        <p class="paragraph-43">http://www.Rankit Media Hub SRL.com, secondo la normativa vigente, non è tenuto a chiedere consenso per i <strong>cookie tecnici</strong> e di <strong>analytics</strong>, in quanto necessari a fornire i servizi richiesti.<br/></p>
        <p class="paragraph-43">Per tutte le altre tipologie di cookie il consenso può essere espresso dall’Utente con una o più di una delle seguenti modalità:<br/></p>
      </div>
      <ul role="list">
        <li class="list-item-4"><strong>Mediante specifiche configurazioni del browser</strong> utilizzato o dei relativi programmi informatici utilizzati per navigare le pagine che compongono il Sito.<br/></li>
        <li class="list-item-5"><strong>Mediante modifica delle impostazioni</strong> nell’uso dei servizi di terze parti<br/></li>
      </ul>
      <p class="paragraph-43">Entrambe queste soluzioni potrebbero impedire all’utente di utilizzare o visualizzare parti del Sito.<br/></p>
      <p class="paragraph-43">Siti Web e servizi di terze parti<br/></p>
      <p class="paragraph-43">Il Sito potrebbe contenere collegamenti ad altri siti Web che dispongono di una propria informativa sulla privacy che può essere diverse da quella adottata da http://www.rankit.it e che quindi <strong>non risponde</strong> di questi siti.<br/></p>
*/}
              {/*  <h1 class="heading-73"><strong class="bold-text-2">Cookie utilizzati da http://www.rankit.it<br/></strong><br/></h1>
      <p class="paragraph-43">A seguire la lista di cookie tecnici e di terze parti utilizzati sul Sito:<br/></p>
      <p class="paragraph-43"><strong class="bold-text-4">Cookie di terze parti:</strong><br/></p>
      <ul role="list">
        <li class="list-item-7"><strong class="bold-text-6">Google Analytics</strong><br/></li>
      </ul>
      <h1 class="heading-74"><strong class="bold-text-2">Cookie “Flash Player” di “Adobe Flash Player”<br/></strong><br/></h1>
      <p class="paragraph-43">http://www.rankit.it non utilizza programmazione, applicazioni e/o plug-in realizzati in linguaggio Flash. È possibile comunque consultare l’informativa sulla privacy di Adobe cliccando sul link in basso.<br/><br/>Per maggiori informazioni visita la <a href="http://www.adobe.com/it/privacy/policy.html" target="_blank">pagina dedicata</a>.<br/></p>
    */}
              <h4>
                <strong>How to disable cookies by browser configuration</strong>
              </h4>
              <p class="paragraph-43">
                The list below lists the configuration procedures for the most
                popular and used web browsers, for browsers not listed, it is
                recommended to view the relevant information in your browser.
              </p>
              <h4>
                <strong>Google Chrome</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>Run the Chrome Browser</li>
                <li>
                  Click on the menu in the browser toolbar next to the url entry
                  window for navigation
                </li>
                <li>
                  Select <strong>Settings</strong>
                </li>
                <li>
                  Click on <strong>Show Advanced Settings</strong>
                  <br />
                </li>
                <li>
                  In the "Privacy" section click on{" "}
                  <strong>"Content Settings"</strong> button
                </li>
                <li>
                  In the "Cookies" section you can change the following cookie
                  settings:
                  <br />
                </li>
              </ol>
              <p class="subheading">
                -&gt; Allow local data storage
                <br />
                -&gt; Change local data only until the browser is closed
                <br />
                -&gt; Prevent sites from setting cookies
                <br />
                -&gt; Block third-party cookies and website data
                <br />
                -&gt; Managing exceptions for certain websites
                <br />
                -&gt; Delete one or all cookies
                <br />
              </p>
              <p class="paragraph-43">
                For more information visit{" "}
                <a
                  href="https://support.google.com/accounts/answer/61416?hl=en"
                  target="_blank"
                >
                  the dedicated page.
                </a>
                <br />
              </p>
              <h4>
                <strong>Mozilla Firefox</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>Run the Firefox Browser</li>
                <li>
                  Click on the menu in the browser toolbar next to the url entry
                  window for navigation
                </li>
                <li>
                  Select <strong>Options</strong>
                </li>
                <li>
                  Select the <strong>Privacy</strong> panel
                </li>
                <li>
                  Click on <strong>Show Advanced Settings</strong>
                  <br />
                </li>
                <li>
                  In the "Privacy" section click on{" "}
                  <strong>"Content Settings"</strong> button
                </li>
                <li>
                  In the <strong>"Tracking"</strong> section you can change the
                  following cookie settings:
                </li>
              </ol>
              <p class="subheading">
                -&gt; Request sites not to do any tracking
                <br />
                -&gt; Notify sites of willingness to be tracked
                <br />
                -&gt; Do not communicate preferences regarding the tracking of
                personal data
                <br />
              </p>
              <ol role="list" class="list-5" start="8">
                <li>Running the Firefox Browser</li>
              </ol>
              <p class="subheading">
                -&gt; By enabling "Use custom settings" select to accept
                third-party cookies (always, from the most visited websites or
                never) and store them for a certain period (until their
                expiration, when closing Firefox or to ask each time) -&gt;
                Remove individual stored cookies
              </p>
              <p class="paragraph-43">
                For more information visit the{" "}
                <a
                  href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it#firefox:win10:fx110"
                  target="_blank"
                >
                  dedicated page.
                </a>
                <br />
                <br />
              </p>

              <h4>
                <strong>Microsoft Edge</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>Run the Microsoft Edge Browser</li>
                <li>
                  Click the button <strong>. . .</strong> choose{" "}
                  <strong>Settings</strong>
                  <br />
                </li>
                <li>
                  On the next screen, scroll to{" "}
                  <strong>Advanced Settings</strong> and click{" "}
                  <strong>View Advanced Settings</strong>
                </li>
                <li>
                  Once you see the advanced settings, scroll down until you
                  reach the Cookies item and select the desired setting:
                </li>
              </ol>
              <p class="subheading">
                -&gt; Block all cookies
                <br />
                -&gt; Block only third-party cookies
                <br />
                -&gt; Do not block cookies
                <br />
              </p>
              <p class="paragraph-43">
                For more information visit the{" "}
                <a
                  href="https://support.microsoft.com/en-gb/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                  target="_blank"
                >
                  dedicated page.
                </a>
                <br />
              </p>

              <h4>
                <strong>Microsoft Internet Explorer</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>
                  Run the Internet Explorer Browser
                  <br />
                </li>
                <li>
                  Click the <strong>Tools</strong> button and choose{" "}
                  <strong>Internet Options</strong>
                </li>
                <li>
                  Click on the <strong>Privacy</strong> tab and in the Settings
                  section adjust the slider according to the desired action for
                  cookies:
                </li>
              </ol>
              <p class="subheading">
                -&gt; Block all cookies
                <br />
                -&gt; Allow all cookies
                <br />
                -&gt; Selection of websites from which to obtain cookies: move
                the cursor to an intermediate position so as not to block or
                allow all cookies, then press on Websites, in the box Website
                address enter a website and then press on Block or Allow
              </p>
              <p class="paragraph-43">
                For more information visit the{" "}
                <a
                  href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  target="_blank"
                >
                  dedicated page.
                </a>
                <br />
              </p>

              <h4>
                <strong>Apple Safari 6 and/or higher</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>Run the Safari Browser</li>
                <li>
                  Click <strong>Safari</strong>, select{" "}
                  <strong>Preferences/Settings</strong> and press{" "}
                  <strong>Privacy</strong>
                </li>
                <li>
                  In the section <strong>Block All Cookies</strong> specify how
                  Safari must accept cookies from websites
                </li>
                <li>
                  To view which websites have stored cookies click on{" "}
                  <strong>Details</strong>
                </li>
              </ol>
              <p class="paragraph-43">
                For more information visit the{" "}
                <a
                  href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac"
                  target="_blank"
                >
                  dedicated page.
                </a>
                <br />
              </p>

              <h4>
                <strong>Apple Safari iOS (mobile devices)</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>Run the iOS Safari Browser</li>
                <li>
                  Tap <strong>Settings</strong> and then <strong>Safari</strong>
                </li>
                <li>
                  In the section <strong>Block All Cookies</strong> specify how
                  Safari must accept cookies from websites
                </li>
                <li>
                  To view which websites have stored cookies click on{" "}
                  <strong>Details</strong>
                </li>
              </ol>
              <p class="paragraph-43">
                For more information visit the{" "}
                <a
                  href="https://support.apple.com/en-us/HT201265"
                  target="_blank"
                >
                  dedicated page.
                </a>
                <br />
              </p>

              <h4>
                <strong>Opera</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>Run the Opera Browser</li>
                <li>
                  Click on Preferences then on Advanced and finally on Cookie
                </li>
                <li>Select one of the following options:</li>
              </ol>
              <p class="subheading">
                -&gt; Accept all cookies
                <br />
                -&gt; Accept cookies only from the website you visit:
                third-party cookies that are sent from a domain other than the
                one you are visiting will be rejected
                <br />
                -&gt; Never accept cookies: all cookies will never be saved
              </p>
              <p class="paragraph-43">
                For more information visit the{" "}
                <a
                  href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"
                  target="_blank"
                >
                  dedicated page.
                </a>
                <br />
              </p>

              <h4>
                <strong>Icons of Social Network</strong>
              </h4>
              <p class="paragraph-43">
                On our Website you can also find social buttons/widgets, or
                particular "buttons" depicting the icons of social networks
                (Facebook, Twitter, YouTube, Google+, LinkedIn, Tumblr,
                Pinterest, Instagram). These "buttons" allow you to reach and
                interact with a "click" directly with social networks. By
                clicking on the Social buttons/widgets, the social network
                acquires data relating to the User's visit. Outside of these
                cases, in which the User spontaneously wants to share with the
                click their browsing data with the social networks chosen,{" "}
                <strong>Rankit Media Hub SRL</strong> does not share any
                browsing information or data of Users acquired through the
                Websites with social networks accessible through Social
                buttons/widgets. For more information you can consult
              </p>
              <h4>
                <strong>Third Party Services Terms & Privacy Policy</strong>
              </h4>
              <ul role="list" class="list-5">
                <li>
                  <a
                    href="https://www.google.it/intl/it/policies/privacy"
                    target="_blank"
                  >
                    Google Services (Search, Gmail, Google+, Maps, Drive,
                    Analytics, Adword, Adsense, ecc..)
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://support.google.com/youtube/answer/2801895?hl=it"
                    target="_blank"
                  >
                    YouTube
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/help/cookies?ref_type=sitefooter"
                    target="_blank"
                  >
                    Facebook
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://support.twitter.com/articles/20170519-uso-dei-cookie-e-di-altre-tecnologie-simili-da-parte-di-twitter"
                    target="_blank"
                  >
                    Twitter
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/legal/cookie_policy"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/about/legal/privacy"
                    target="_blank"
                  >
                    Instagram
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://www.tumblr.com/policy/en/privacy"
                    target="_blank"
                  >
                    Tumblr
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://www.tumblr.com/policy/en/privacy"
                    target="_blank"
                  >
                    Pinterest
                  </a>
                  <br />
                </li>
              </ul>
              <p class="paragraph-43">
                This page is visible, through link at the bottom of all the
                pages of the Website.
                <br />
              </p>
              {/*<h1 class="heading-75"><strong class="bold-text-2">Aggiornamento informativa<br/></strong><br/></h1>*/}
              <p>
                ‍<strong>Last update:</strong> December 11, 2024
                <br />
              </p>
              <p class="paragraph-43">
                Rankit Media Hub SRL
                <br />
                Viale Liguria 24, Milano 20143 - C.F e P.I 13836910961
                <br />
                <a href="mailto:info(@)rankitmediahub.com">
                  Info(@)rankitmediahub.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EnCookiePolicy;
