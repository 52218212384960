import React, { useState, useEffect, useRef } from "react";
import ellipse2 from "../../Images/ellipse2.png";
import Instagram from "../../Images/Icons/instagram.png";
import Facebook from "../../Images/Icons/facebook.png";
import Twitter from "../../Images/Icons/twitter.png";
import Linkedin from "../../Images/Icons/linkedin.png";
import { Trans, useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { config_data } from "../../config.js";

function ContactUs() {
  const { t } = useTranslation();

  const [contactsName, setContactsName] = useState(undefined);
  const [contactsEmail, setContactsEmail] = useState(undefined);
  const [contactsSubject, setContactsSubject] = useState(undefined);
  const [contactsMessage, setContactsMessage] = useState(undefined);
  const [contactsCheckbox, setContactsCheckbox] = useState(false);
  const [contactsChecked, setContactsChecked] = useState(false);

  const send = async () => {
    var filter =
      /^([a-zA-Z0-9_+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (contactsEmail == undefined || !filter.test(contactsEmail)) {
      toast(t("signin_errors_email"));
      return false;
    } else if (contactsName == "" || contactsName == undefined) {
      toast(t("signup_errors_firstname"));
      return false;
    } else if (contactsSubject == "" || contactsSubject == undefined) {
      toast(t("contact_us_errors_subject"));
      return false;
    } else if (contactsMessage == "" || contactsMessage == undefined) {
      toast(t("contact_us_errors_message"));
      return false;
    } else if (contactsCheckbox == false) {
      toast(t("contact_us_errors_checkbox"));
      return false;
    } else {
      const response = await axios.post(`${config_data["node_url"]}/sendmail`, {
        name: contactsName,
        email: contactsEmail,
        subject: contactsSubject,
        message: contactsMessage,
        magic: "EregaRchiCORDAyDreaR",
      });
      console.log(response);
      if (response["data"]["result"] == "error") {
        toast(response["data"]["data"]);
      } else {
        toast(t("contact_us_result_ok"));
      }
      setContactsEmail("");
      setContactsMessage("");
      setContactsName("");
      setContactsSubject("");
      setContactsChecked(false);
      document.getElementById("contacts_checkbox").checked = false;
    }
  };

  return (
    <div class="section contact" id="contact">
      <div class="container-fluid ps-lg-5 ps-md-5">
        <div class="row">
          <div class="col-lg-6 pb-5">
            <img
              class="img-fluid ellipse-img"
              src={ellipse2}
              alt="ellipse-img"
            />
            <h2 class="pt-4">
              <Trans>
                {t("contact_us_title", {
                  interpolation: { escapeValue: false },
                })}
              </Trans>
            </h2>
            <p>
              <Trans>
                {t("contact_us_text", {
                  interpolation: { escapeValue: false },
                })}
              </Trans>
            </p>
            <form action="/">
              <div class="my-3">
                <input
                  value={contactsName}
                  onChange={(e) => setContactsName(e.target.value)}
                  type="text"
                  class="form-control"
                  id="nameInput"
                  placeholder={t("contact_us_placeholder_1", {
                    interpolation: { escapeValue: false },
                  })}
                  required
                  aria-label={t("contact_us_placeholder_1", {
                    interpolation: { escapeValue: false },
                  })}
                />
              </div>
              <div class="mb-3">
                <input
                  value={contactsEmail}
                  onChange={(e) => setContactsEmail(e.target.value)}
                  type="email"
                  class="form-control"
                  id="emailInput"
                  required
                  placeholder={t("contact_us_placeholder_2", {
                    interpolation: { escapeValue: false },
                  })}
                />
              </div>
              <div class="mb-3">
                <input
                  value={contactsSubject}
                  onChange={(e) => setContactsSubject(e.target.value)}
                  type="text"
                  class="form-control"
                  id="subjectInput"
                  placeholder={t("contact_us_placeholder_3", {
                    interpolation: { escapeValue: false },
                  })}
                  aria-label={t("contact_us_placeholder_3", {
                    interpolation: { escapeValue: false },
                  })}
                />
              </div>
              <div class="mb-3">
                <textarea
                  value={contactsMessage}
                  onChange={(e) => setContactsMessage(e.target.value)}
                  class="form-control"
                  placeholder={t("contact_us_placeholder_4", {
                    interpolation: { escapeValue: false },
                  })}
                  id="messageInput"
                  rows="5"
                ></textarea>
              </div>
              <div class="mb-3">
                <input
                  id="contacts_checkbox"
                  value={contactsChecked}
                  type="checkbox"
                  style={{ verticalAlign: "middle" }}
                  onClick={() => {
                    setContactsCheckbox(!contactsCheckbox);
                  }}
                />
                <Trans>
                  {t("contact_us_accept_1", {
                    interpolation: { escapeValue: false },
                  })}
                </Trans>
                <a target="_blank" href={t("contact_us_accept_url")}>
                  <Trans>
                    {t("contact_us_accept_2", {
                      interpolation: { escapeValue: false },
                    })}
                  </Trans>
                </a>
                <Trans>
                  {t("contact_us_accept_3", {
                    interpolation: { escapeValue: false },
                  })}
                </Trans>
              </div>
              <div class="mb-3">
                <button
                  type="button"
                  class="btn btn-contact"
                  onClick={() => {
                    send();
                  }}
                >
                  <Trans>
                    {t("contact_us_button", {
                      interpolation: { escapeValue: false },
                    })}
                  </Trans>
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-4 contact-bg py-4">
            <div class="contact-card my-lg-5 py-lg-4">
              <h4 class="ps-4 pt-5">Info</h4>
              <ul class="pt-4">
                <li>
                  <a href="/">
                    <svg
                      class="svg-icon"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M36.25 6.25H3.75C3.05859 6.25 2.5 6.80859 2.5 7.5V32.5C2.5 33.1914 3.05859 33.75 3.75 33.75H36.25C36.9414 33.75 37.5 33.1914 37.5 32.5V7.5C37.5 6.80859 36.9414 6.25 36.25 6.25ZM34.6875 10.5781V30.9375H5.3125V10.5781L4.23438 9.73828L5.76953 7.76563L7.44141 9.06641H32.5625L34.2344 7.76563L35.7695 9.73828L34.6875 10.5781ZM32.5625 9.0625L20 18.8281L7.4375 9.0625L5.76563 7.76172L4.23047 9.73438L5.30859 10.5742L18.6523 20.9492C19.0361 21.2473 19.5082 21.4092 19.9941 21.4092C20.4801 21.4092 20.9522 21.2473 21.3359 20.9492L34.6875 10.5781L35.7656 9.73828L34.2305 7.76563L32.5625 9.0625Z"
                        fill="white"
                      />
                    </svg>{" "}
                    Info(@)rankitmediahub.com
                  </a>
                </li>
                {/* <li>
                                <a href="/"><svg class="svg-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.6164 36.6669C28.059 36.6695 28.4978 36.5838 28.907 36.415C29.3162 36.2462 29.6877 35.9975 29.9997 35.6836L34.5164 31.1669C34.8268 30.8546 35.0011 30.4322 35.0011 29.9919C35.0011 29.5516 34.8268 29.1292 34.5164 28.8169L27.8497 22.1502C27.5375 21.8398 27.115 21.6656 26.6747 21.6656C26.2344 21.6656 25.812 21.8398 25.4997 22.1502L22.8331 24.8002C20.9868 24.308 19.2769 23.4017 17.8331 22.1502C16.5849 20.7041 15.6791 18.995 15.1831 17.1502L17.8331 14.4836C18.1435 14.1713 18.3177 13.7489 18.3177 13.3086C18.3177 12.8683 18.1435 12.4458 17.8331 12.1336L11.1664 5.4669C10.8541 5.15648 10.4317 4.98225 9.9914 4.98225C9.55109 4.98225 9.12867 5.15648 8.8164 5.4669L4.3164 10.0002C4.00245 10.3123 3.75381 10.6838 3.58497 11.093C3.41614 11.5022 3.33051 11.9409 3.33306 12.3836C3.48427 18.7874 6.04319 24.899 10.4997 29.5002C15.1009 33.9568 21.2126 36.5157 27.6164 36.6669ZM9.99973 9.0169L14.3164 13.3336L12.1664 15.4836C11.9628 15.6743 11.8103 15.9131 11.7229 16.1781C11.6356 16.4431 11.6161 16.7258 11.6664 17.0002C12.2893 19.7842 13.6082 22.3646 15.4997 24.5002C17.6337 26.3941 20.2148 27.7134 22.9997 28.3336C23.27 28.3901 23.5501 28.3785 23.8149 28.3C24.0796 28.2214 24.3207 28.0783 24.5164 27.8836L26.6664 25.6836L30.9831 30.0002L27.6497 33.3336C22.1226 33.1913 16.8489 30.9855 12.8664 27.1502C9.02125 23.1661 6.80908 17.8854 6.6664 12.3502L9.99973 9.0169ZM33.3331 18.3336H36.6664C36.7096 16.3519 36.3512 14.382 35.6126 12.5426C34.8741 10.7032 33.7708 9.03231 32.3692 7.63073C30.9677 6.22914 29.2968 5.12584 27.4574 4.38732C25.618 3.6488 23.6481 3.29035 21.6664 3.33357V6.6669C23.2129 6.61341 24.7539 6.87859 26.1936 7.44596C27.6332 8.01333 28.9408 8.87077 30.035 9.96497C31.1292 11.0592 31.9866 12.3667 32.554 13.8064C33.1214 15.2461 33.3866 16.7871 33.3331 18.3336Z" fill="white"/>
                                <path d="M21.667 13.3333C25.167 13.3333 26.667 14.8333 26.667 18.3333H30.0003C30.0003 12.9667 27.0337 10 21.667 10V13.3333Z" fill="white"/>
                                </svg>
                                    (505) 555-0125</a></li>*/}
                <li>
                  <a href="/">
                    <svg
                      class="svg-icon"
                      width="38"
                      height="40"
                      viewBox="0 0 38 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M36.9075 0.187206C37.0886 0.299192 37.238 0.45557 37.3417 0.641514C37.4454 0.827458 37.4999 1.0368 37.5 1.24971V38.7497C37.5 39.0812 37.3683 39.3992 37.1339 39.6336C36.8995 39.868 36.5815 39.9997 36.25 39.9997H28.75C28.4185 39.9997 28.1005 39.868 27.8661 39.6336C27.6317 39.3992 27.5 39.0812 27.5 38.7497V34.9997H25V38.7497C25 39.0812 24.8683 39.3992 24.6339 39.6336C24.3995 39.868 24.0815 39.9997 23.75 39.9997H1.25C0.918479 39.9997 0.600537 39.868 0.366117 39.6336C0.131696 39.3992 0 39.0812 0 38.7497V24.9997C0.000199117 24.7375 0.0828702 24.4819 0.236312 24.2693C0.389754 24.0566 0.606195 23.8976 0.855 23.8147L15 19.0997V11.2497C15 11.0178 15.0646 10.7904 15.1864 10.593C15.3083 10.3957 15.4826 10.2361 15.69 10.1322L35.69 0.132206C35.8808 0.0367207 36.0928 -0.00835351 36.3059 0.0012737C36.519 0.0109009 36.7261 0.0749092 36.9075 0.187206ZM15 21.7347L2.5 25.8997V37.4997H15V21.7347ZM17.5 37.4997H22.5V33.7497C22.5 33.4182 22.6317 33.1002 22.8661 32.8658C23.1005 32.6314 23.4185 32.4997 23.75 32.4997H28.75C29.0815 32.4997 29.3995 32.6314 29.6339 32.8658C29.8683 33.1002 30 33.4182 30 33.7497V37.4997H35V3.27221L17.5 12.0222V37.4997Z"
                        fill="white"
                      />
                      <path
                        d="M5 27.5H7.5V30H5V27.5ZM10 27.5H12.5V30H10V27.5ZM5 32.5H7.5V35H5V32.5ZM10 32.5H12.5V35H10V32.5ZM20 22.5H22.5V25H20V22.5ZM25 22.5H27.5V25H25V22.5ZM20 27.5H22.5V30H20V27.5ZM25 27.5H27.5V30H25V27.5ZM30 22.5H32.5V25H30V22.5ZM30 27.5H32.5V30H30V27.5ZM20 17.5H22.5V20H20V17.5ZM25 17.5H27.5V20H25V17.5ZM30 17.5H32.5V20H30V17.5ZM20 12.5H22.5V15H20V12.5ZM25 12.5H27.5V15H25V12.5ZM30 12.5H32.5V15H30V12.5ZM30 7.5H32.5V10H30V7.5Z"
                        fill="white"
                      />
                    </svg>{" "}
                    Viale Liguria 24 - Milano (MI) 20143
                  </a>
                </li>
                <li>
                  <a href="/">
                    <svg
                      class="svg-icon"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 2.5C10.3359 2.5 2.5 10.3359 2.5 20C2.5 29.6641 10.3359 37.5 20 37.5C29.6641 37.5 37.5 29.6641 37.5 20C37.5 10.3359 29.6641 2.5 20 2.5ZM20 34.5312C11.9766 34.5312 5.46875 28.0234 5.46875 20C5.46875 11.9766 11.9766 5.46875 20 5.46875C28.0234 5.46875 34.5312 11.9766 34.5312 20C34.5312 28.0234 28.0234 34.5312 20 34.5312Z"
                        fill="white"
                      />
                      <path
                        d="M26.8242 24.9453L21.2539 20.918V11.25C21.2539 11.0781 21.1133 10.9375 20.9414 10.9375H19.0625C18.8906 10.9375 18.75 11.0781 18.75 11.25V22.0078C18.75 22.1094 18.7969 22.2031 18.8789 22.2617L25.3398 26.9727C25.4805 27.0742 25.6758 27.043 25.7773 26.9062L26.8945 25.3828C26.9961 25.2383 26.9648 25.043 26.8242 24.9453Z"
                        fill="white"
                      />
                    </svg>{" "}
                    24/7
                  </a>
                </li>
              </ul>
            </div>
            <div class="text-center align-self-end text-light">
              <h3 class="py-2">Follow us</h3>
              <a
                class=""
                href="https://instagram.com/rankit_platform?igshid=YmMyMTA2M2Y="
                target="_blank"
              >
                <img class="" src={Instagram} alt="instagram-icon" />
              </a>
              <a
                class=""
                href="https://facebook.com/RankitItalia"
                target="_blank"
              >
                <img class="mx-5" src={Facebook} alt="facebook-icon" />
              </a>
              <a
                class=""
                href="https://www.linkedin.com/company/rankit"
                target="_blank"
              >
                <img class="" src={Linkedin} alt="linkedin-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ContactUs;
