import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackgroundSvg1 from "../components/BackgroundImages/backgroundSvg1";
import Rankit from "../Images/Rankit-Logo.png";
import Navbar from "../components/Navbar";
import { Trans, useTranslation } from "react-i18next";
import { config_data } from "../config.js";
import { useCookies } from "react-cookie";

const ItTermsConditions = () => {
  const { t } = useTranslation();

  return (
    <div class="dark">
      <Navbar />
      <section class="signIn my-5 py-5">
        <div class="container my-3">
          <div class="row align-items-center">
            <div class="col-lg-12 picture" style={{ color: "white" }}>
              <h3 class="title my-5" align="center">
                Termini e Condizioni
              </h3>

              <p>
                Rankit.it è di proprietà di Rankit Media Hub SRL, partita iva
                13836910961, sede legale Viale Liguria ,24 20143 Milano (MI).
                <br />
                Iscrivendoti e usufruendo dei servizi di Rankit dichiari di aver
                preso visione e di aver accettato i seguenti termini e
                condizioni di servizio.
                <br />
                Rankit Media Hub SRL si riserva il diritto di modificare in
                qualsiasi momento le seguenti condizioni. L'uso continuativo del
                software rankit.it, anche a seguito di modifiche al presente
                accordo, ne costituisce l'accettazione implicita.
              </p>
              <h4>
                <strong>Registrazione e account</strong>
              </h4>
              <p>
                Al momento della registrazione le informazioni inserite devono
                essere accurate e veritiere. La comunicazione dei dati richiesti
                è imprescindibile per l'utilizzo del servizio. Non è consentito
                utilizzare dati identificativi di altre persone. L'iscritto è il
                solo responsabile delle attività compiute dal suo Account e
                della sicurezza della sua password. Non è consentito utilizzare
                l'account di altre persone senza l'autorizzazione. L'iscritto
                deve notificare immediatamente Rankit nel caso in cui
                sospettasse attività sospette sul proprio account. Non è
                consentito pubblicare o comunicare le chiavi d'accesso del
                proprio account.
              </p>
              <h4>
                <strong>Utilizzo e rapporto</strong>
              </h4>
              <p>
                Rankit Media Hub SRL garantisce ai Driver e Advertiser iscritti
                l'uso non esclusivo e non trasferibile del servizio di
                marketplace della piattaforma.
                <br />
                Nessuna altra licenza o uso è garantita, Rankit Media Hub SRL
                detiene tutti i diritti (inclusa la proprietà intellettuale e
                ogni altro diritto annesso) di ogni materiale, risorsa, codice
                contenuto nella piattaforma e sui suoi servizi.
                <br />
                I Driver, blogger (publisher) non sono da considerarsi in alcun
                modo come collaboratori fissi o dipendenti.
                <br />
                Il Driver è responsabile della dichiarazione dei propri compensi
                e al pagamento delle tasse previste a norma di legge.
              </p>

              <h4>
                <strong>Restrizioni</strong>
              </h4>
              <p>
                Non è consentito rendere pubbliche, in modo diretto o indiretto,
                nessuna informazione confidenziale della piattaforma Rankit e
                dei suoi contenuti. Non è consentito utilizzare nessuna
                informazione per creare servizi, software, documentazione di
                ogni genere, studi di mercato e ogni utilizzo assimilabile ai
                sopracitati. Non è consentito riprodurre nessuna parte del
                codice di Rankit per nessun utilizzo.
              </p>

              <h3>
                <strong>Driver (Publisher)</strong>
              </h3>
              <h4>
                <strong>Adesione</strong>
              </h4>
              <p>
                Per Driver (publisher) si intente qualsiasi iscritto a Rankit in
                qualità di driver, blogger, possessore di account sui Social
                Network che intenda aderire e pubblicare in forma di contenuto
                le comunicazioni dei clienti Rankit Media Hub SRL che utilizzano
                il software Rankit (Advertiser). La registrazione e i dati
                richiesti sono obbligatori per utilizzare la piattaforma e sono
                utilizzati esclusivamente per la gestione dell'attività inclusa
                in Rankit.
                <br />
                Il Driver si impegna ad aggiornare il proprio il proprio profilo
                qualora i dati inseriti dovessero subire modifiche nel corso del
                tempo.
              </p>

              <h4>
                <strong>Pubblicazioni</strong>
              </h4>
              <p>
                Il Driver aderendo alle campagne si impegna a rispettarne i
                contenuti e non alterare il messaggio nella pubblicazione.
                <br />I contenuti pubblicati sui social network e sui
                blog/siti/community che si discostino dalla comunicazione
                indicata da Rankit verranno considerati illeciti e non verranno
                remunerati. Saranno inoltre ritenuti contro policy:
                <ul>
                  <li>
                    Qualunque pubblicazione su social netowork non indicati per
                    ciascuna campagna
                  </li>
                  <li>
                    Qualunque pubblicazione su siti/blog/community non indicati
                    per ciascuna campagna e/o non indicati sul profilo Driver
                  </li>
                  <li>
                    Qualunque contenuto manipolato al fine di ottenere più clic
                    che si discosti dalla comunicazione indicata su Rankit
                  </li>
                  <li>
                    Qualunque clic generato da sistemi automatici fraudolenti
                  </li>
                  <li>
                    Qualunque pubblicazione, redazione di contenuto volto a
                    ledere l'immagine dei clienti Rankit Media Hub SRL che
                    utilizzano il software Rankit.
                  </li>
                  <li>
                    Immagini, contenuti e messaggi offensivi, contro la legge, a
                    danno di qualsiasi brand o persona, contenenti diffamazioni,
                    calunnie, materiali vietati ai minori di 18 anni, violenti.
                  </li>
                </ul>
                Rankit Media Hub SRL si riserva il diritto di estromettere dalla
                piattaforma gli utenti che in qualunque modo non operino secondo
                i termini e condizioni a suo insindacabile giudizio.
              </p>

              <h4>
                <strong>Materiali</strong>
              </h4>
              <p>
                Il Driver si impegna ad utilizzare esclusivamente i materiali
                forniti da Rankit Media Hub SRL attraverso il software Rankit
                per la redazione di contenuti richiesti. Ogni altro materiale
                utilizzato senza previa autorizzazione comprometterà la
                remunerazione dello stesso. Il Driver è l'unico responsabile per
                l'utilizzo di materiale non approvato e soggetto a copyright.
              </p>

              <h4>
                <strong>Campagne</strong>
              </h4>
              <p>
                I servizi offerti da Rankit Media Hub SRL attraverso il software
                Rankit restano disponibili all'adesione per il periodo indicato
                al momento dell'adesione e per un numero massimo di partecipanti
                nonché per budget prefissato giornaliero/settimanale/mensile.
                Raggiunto il numero massimo di adesioni e/o clic Il Driver non
                potrà più aderire. In questo caso, non sarà possibile per il
                Driver avanzare alcuna pretesa nei confronti di Rankit Media Hub
                SRL. Il Driver si impegna a non cancellare contenuti pubblicati
                e/o redatti per almeno 30 giorni pena la non remunerazione del
                contenuto fatta eccezione per i formati Instagram Stories cui
                durata è di 24h dalla pubblicazione del post.
                <br />
                Rankit non si assume alcuna responsabilità in relazione a link e
                relativi contenuti terzi inseriti nel proprio sito. Rankit Media
                Hub SRL non garantisce sulla qualità dei sistemi e siti di
                proprietà di terze parti e non è responsabile in caso di
                presenza di malware o altri sistemi dannosi.
              </p>

              <h4>
                <strong>Compenso</strong>
              </h4>
              <p>
                Per ciascuna tipologia di campagna è indicato il compenso per
                l'attività in termini di clic, pubblicazione materiali e
                redazione di contenti sui propri siti/blog/community. Il
                pagamento del corrispettivo è cumulativo per tutte le campagne a
                cui il Driver ha aderito e si riferisce al mese solare.
                <br />
                Il pagamento potrà essere richiesto dal Driver il 1° di ogni
                mese. I Driver dovrà presentare fattura o ritenuta d'acconto
                entro il 10 dello stesso mese. Il pagamento avverrà il 10 del
                mese successivo alla richiesta sotto forma di bonifico bancario
                ai dati indicati in fattura/ritenuta. Il Driver si impegna ad
                aggiornare i termini e i dati per il pagamento dei corrispettivi
                entro 7 giorni dalla loro variazione.
              </p>

              <h4>
                <strong>Materiale promozionali</strong>
              </h4>
              <p>
                Rankit si riserva il diritto di utilizzare nomi, domini,
                community, profili social dei Driver a fini promozionali,
                formazione, marketing e comunicazioni volte a esplicare le
                attività di Rankit.
              </p>

              <h3>
                <strong>Advertiser</strong>
              </h3>
              <h4>
                <strong>Adesione</strong>
              </h4>
              <p>
                Per Advertiser si intente qualsiasi iscritto al software Rankit
                alla sezione advertiser e che intenda usufruire dei servizi di
                Rankit Media Hub SRL attraverso il software Rankit per divulgare
                contenuti, materiali, iniziative, commissionare contenuti
                editoriali al fine di promuovere un prodotto, evento,
                comunicazione pubblicitaria. La registrazione e i dati richiesti
                sono obbligatori per utilizzare la piattaforma e sono utilizzati
                esclusivamente per la gestione dell'attività inclusa in Rankit.
                <br />
                L'Advertiser si impegna ad aggiornare il proprio il proprio
                profilo qualora i dati inseriti dovessero subire modifiche nel
                corso del tempo.
              </p>

              <h4>
                <strong>Campagne</strong>
              </h4>
              <p>
                L’Advertiser, una volta ricaricato il proprio conto, può
                usufruire della piattaforma. L’Advertiser può inserire
                autonomamente i propri contenuti. La misurazione secondo i
                propri sistemi di monitoraggio è a discrezione dell’Advertiser.
                Rankit Media Hub SRL si impegna a fornire assistenza
                esclusivamente sull’utilizzo della piattaforma in oggetto. I
                contenuti NON ammessi alla divulgazione:
                <ul>
                  <li>Materiale vietato ai minori di 18 anni di età;</li>
                  <li>
                    Materiale contenente immagini o espressioni violente,
                    illegali, razziste e che turbino in qualche modo la
                    sensibilità di ciascuna minoranza;
                  </li>
                  <li>
                    Materiale di cui non si detengono i diritti di copyright;
                  </li>
                  <li>
                    Link e contenuti contenenti malware e sistemi dannosi per
                    gli utenti;
                  </li>
                  <li>Contenuti ingannevoli ai danni dei visitatori;</li>
                </ul>
                Rankit Media Hub SRL non si assume alcuna responsabilità in
                relazione a link e relativi contenuti terzi inseriti nel proprio
                sito. Rankit Media Hub SRL non garantisce sulla qualità dei
                sistemi e siti di proprietà di terze parti e non è responsabile
                in caso di presenza di malware o altri sistemi dannosi.
              </p>

              <h4>
                <strong>Pagamento</strong>
              </h4>
              <p>
                I servizi offerti da Rankit Media Hub SRL attraverso il software
                Rankit sono disponibili una volta ricaricato il proprio conto
                tramite PayPal e/o bonifico bancario e comprendono campagne e
                attività coperte da suddetto budget. Il budget può essere
                ricaricato più volte.
                <br />
                Rankit Media Hub SRL si impegna ad emettere fattura entro 30
                giorni dal termine di ciascun budget. L’Advertiser si impegna a
                fornire dati di fatturazione veritieri e aggiornati.
              </p>

              <h4>
                <strong>Materiale promozionale</strong>
              </h4>
              <p>
                Rankit Media Hub SRL si riserva il diritto di utilizzare nomi,
                domini, community, profili social dei Driver a fini
                promozionali, formazione, marketing e comunicazioni volte a
                esplicare le attività di Rankit Media Hub SRL attraverso il
                software Rankit.
              </p>
            </div>

            {/*<div class="col-lg-6 SignIn Forms px-5">

    <h3 class="title my-5">
    <Trans>{t('signin_title', {interpolation: {escapeValue: false}})}</Trans>
    </h3>


    <div class="form-floating my-4">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" value={signinEmail} onChange={e => setSigninEmail(e.target.value)} />
        <label for="floatingInput"><Trans>{t('signin_email', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      <div class="form-floating my-3">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" value={signinPassword} onChange={e => setSigninPassword(e.target.value)} />
        <label for="floatingPassword"><Trans>{t('signin_password', {interpolation: {escapeValue: false}})}</Trans></label>
      </div>

      {/*<div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        <label class="form-check-label" for="flexCheckDefault">
        <Trans>{t('signin_remember_me', {interpolation: {escapeValue: false}})}</Trans>
        </label>
  </div>

      <button class="btn my-4" onClick={() => {signin()}}><Trans>{t('signin_button', {interpolation: {escapeValue: false}})}</Trans></button>


      <div>
        <a href="/signup" class="my-2 link"><Trans>{t('signin_signup', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

      <div class="my-4 ">
        <a href="/forgotpassword" class="my-4 link"><Trans>{t('signin_forgot', {interpolation: {escapeValue: false}})}</Trans></a>
      </div>

</div>*/}
          </div>
        </div>
      </section>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ItTermsConditions;
