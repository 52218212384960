import React from "react";
import RankitImage from "../../Images/Rankit-Logo.png";
import { Trans, useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";

function Footer() {
  const { t } = useTranslation();
  return (
    <div class="footer">
      <div class="container-fluid px-lg-5 px-md-5">
        <img class="img-fluid pb-lg-5" src={RankitImage} alt="Rankit-logo" />
        <div class="row my-5 ">
          <div class="col-lg-4 px-lg-5">
            <h3>RankIt</h3>
          </div>
          <div class="col-lg-4">
            <ul>
              <li>
                <a href="#">
                  <Trans>
                    {t("footer_1", { interpolation: { escapeValue: false } })}
                  </Trans>
                </a>
              </li>
              <li>
                <a href="#about">
                  <Trans>
                    {t("footer_2", { interpolation: { escapeValue: false } })}
                  </Trans>
                </a>
              </li>
              <li>
                <a href="#content">
                  <Trans>
                    {t("footer_3", { interpolation: { escapeValue: false } })}
                  </Trans>
                </a>
              </li>
              <li>
                <a href="#process">
                  <Trans>
                    {t("footer_4", { interpolation: { escapeValue: false } })}
                  </Trans>
                </a>
              </li>
              <li>
                <a href="#partners">
                  <Trans>
                    {t("footer_5", { interpolation: { escapeValue: false } })}
                  </Trans>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-4">
            <ul>
              <li>
                <a href="#">Rankit Media Hub SRL</a>
              </li>
              <li>
                <a href="#">C.F. e P.IVA 13836910961</a>
              </li>
              <li>
                <a href="#">
                  Sede Legale: Viale Liguria 24 - Milano (MI) 20143
                </a>
              </li>
              <li>
                <a href="#">Info(@)rankitmediahub.com</a>
              </li>
              <li>
                <a target="_blank" href={t("home_footer_privacy_policy_url")}>
                  Privacy Policy
                </a>
              </li>
              <li>
                <a target="_blank" href={t("home_footer_cookie_policy_url")}>
                  Cookie Policy
                </a>
              </li>
              {/*<li><a target='_blank' href={t('home_footer_terms_conditions_url')}><Trans>{t('home_footer_terms_conditions_label', {interpolation: {escapeValue: false}})}</Trans></a></li>*/}
            </ul>
          </div>
          <div class="copyright text-center mt-5 pt-5">
            <p>Rankit copyright @ 2023</p>
          </div>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText={t("home_footer_cookie_banner_button")}
        cookieName="landing_cookie_accept"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        <Trans>
          {t("home_footer_cookie_banner_text", {
            interpolation: { escapeValue: false },
          })}
        </Trans>{" "}
        <a target="_blank" href={t("home_footer_cookie_policy_url")}>
          Cookie Policy
        </a>
        {/*<span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>*/}
      </CookieConsent>
    </div>
  );
}

export default Footer;
