import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackgroundSvg1 from "../components/BackgroundImages/backgroundSvg1";
import Rankit from "../Images/Rankit-Logo.png";
import Navbar from "../components/Navbar";
import { Trans, useTranslation } from "react-i18next";
import { config_data } from "../config.js";
import { useCookies } from "react-cookie";

const ItCookiePolicy = () => {
  const { t } = useTranslation();

  return (
    <div class="dark">
      <Navbar />
      <section class="signIn my-5 py-5">
        <div class="container my-3">
          <div class="row align-items-center">
            <div class="col-lg-12 picture" style={{ color: "white" }}>
              <h3 class="title my-5" align="center">
                Cookie Policy
              </h3>

              <p class="paragraph-44">
                I cookie HTTP (più comunemente denominati cookie web, o per
                antonomasia cookie) sono un tipo particolare di cookie, una
                sorta di gettone identificativo, usato dai server web per poter
                riconoscere i browser durante comunicazioni con il protocollo
                HTTP usato per la navigazione web. http://www.rankit.it o il
                “Sito” utilizza solo Cookie tecnici che non intendono consenso.
              </p>
              {/*<ul role="list" class="list-3">
          <li>memorizzare le preferenze inserite</li>
          <li>evitare di reinserire le stesse informazioni più volte durante la visita quali ad esempio nome utente e password</li>
          <li>analizzare l’utilizzo dei servizi e dei contenuti forniti da http://www.rankit.it per ottimizzarne l’esperienza di navigazione e i servizi offerti<br/></li>
        </ul>*/}
              {/*<h1 class="heading-69"><strong class="bold-text-2">Tipologie di Cookie utilizzati da http://www.rankit.it</strong><br/></h1>
        <p class="paragraph-42">A seguire i vari tipi di cookie utilizzati da http://www.rankit.it in funzione delle finalità d’uso</p>*/}
              <h4>
                <strong>Cookie Tecnici</strong>
              </h4>
              <p class="paragraph-42">
                Questa tipologia di cookie permette il{" "}
                <strong>
                  corretto funzionamento di alcune sezioni del Sito
                </strong>
                . {/*Sono di due categorie: persistenti e di sessione:*/}
              </p>
              <ul role="list" class="list-4">
                {/*  <li><strong>persistenti</strong>: una volta chiuso il browser non vengono distrutti ma rimangono fino ad una data di scadenza preimpostata</li>*/}
                <li>
                  <strong>di sessioni</strong>: vengono distrutti ogni volta che
                  il browser viene chiuso
                </li>
              </ul>
              <p class="paragraph-43">
                Questi cookie, inviati sempre dal nostro dominio,{" "}
                <strong>
                  sono necessari a visualizzare correttamente il sito
                </strong>{" "}
                e in relazione ai servizi tecnici offerti, verranno quindi{" "}
                <strong>sempre utilizzati e inviati</strong>, a meno che
                l’utenza non modifichi le impostazioni nel proprio browser
                (inficiando così la visualizzazione delle pagine del sito).
              </p>
              {/*  <h1 class="heading-71"><strong class="bold-text-2">Cookie analitici<br/></strong><br/></h1>
        <p class="paragraph-43">I cookie in questa categoria vengono utilizzati per <strong>collezionare informazioni sull’uso del sito</strong>. http://www.rankit.it userà queste informazioni in merito ad <strong>analisi statistiche anonime</strong> al fine di migliorare l’utilizzo del Sito e per rendere i contenuti più interessanti e attinenti ai desideri dell’utenza. Questa tipologia di cookie raccoglie dati in <strong>forma anonima</strong> sull’attività dell’utenza e su come è arrivata sul Sito. I cookie analitici sono inviati dal Sito Stesso o da domini di terze parti.<br/></p>

        <h1 class="heading-72"><strong class="bold-text-2">Cookie di profilazione<br/></strong><br/></h1>
        <p class="paragraph-43">Sono quei cookie necessari a creare profili utenti al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dall’utente all’interno delle pagine del Sito.<br/></p>
        <p class="paragraph-43">http://www.Rankit Media Hub SRL.com, secondo la normativa vigente, non è tenuto a chiedere consenso per i <strong>cookie tecnici</strong> e di <strong>analytics</strong>, in quanto necessari a fornire i servizi richiesti.<br/></p>
        <p class="paragraph-43">Per tutte le altre tipologie di cookie il consenso può essere espresso dall’Utente con una o più di una delle seguenti modalità:<br/></p>
      </div>
      <ul role="list">
        <li class="list-item-4"><strong>Mediante specifiche configurazioni del browser</strong> utilizzato o dei relativi programmi informatici utilizzati per navigare le pagine che compongono il Sito.<br/></li>
        <li class="list-item-5"><strong>Mediante modifica delle impostazioni</strong> nell’uso dei servizi di terze parti<br/></li>
      </ul>
      <p class="paragraph-43">Entrambe queste soluzioni potrebbero impedire all’utente di utilizzare o visualizzare parti del Sito.<br/></p>
      <p class="paragraph-43">Siti Web e servizi di terze parti<br/></p>
      <p class="paragraph-43">Il Sito potrebbe contenere collegamenti ad altri siti Web che dispongono di una propria informativa sulla privacy che può essere diverse da quella adottata da http://www.rankit.it e che quindi <strong>non risponde</strong> di questi siti.<br/></p>
*/}
              {/*  <h1 class="heading-73"><strong class="bold-text-2">Cookie utilizzati da http://www.rankit.it<br/></strong><br/></h1>
      <p class="paragraph-43">A seguire la lista di cookie tecnici e di terze parti utilizzati sul Sito:<br/></p>
      <p class="paragraph-43"><strong class="bold-text-4">Cookie di terze parti:</strong><br/></p>
      <ul role="list">
        <li class="list-item-7"><strong class="bold-text-6">Google Analytics</strong><br/></li>
      </ul>
      <h1 class="heading-74"><strong class="bold-text-2">Cookie “Flash Player” di “Adobe Flash Player”<br/></strong><br/></h1>
      <p class="paragraph-43">http://www.rankit.it non utilizza programmazione, applicazioni e/o plug-in realizzati in linguaggio Flash. È possibile comunque consultare l’informativa sulla privacy di Adobe cliccando sul link in basso.<br/><br/>Per maggiori informazioni visita la <a href="http://www.adobe.com/it/privacy/policy.html" target="_blank">pagina dedicata</a>.<br/></p>
    */}
              <h4>
                <strong>
                  Come disabilitare i cookie mediante configurazione del browser
                </strong>
              </h4>
              <p class="paragraph-43">
                Nell’elenco in basso sono elencate le procedure di
                configurazione per i browser web più conosciuti e utilizzati,
                per i browser non in elenco, si consiglia di visionare la
                relativa informativa nel browser in uso.
                <br />
              </p>
              <h4>
                <strong>Google Chrome</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>
                  Eseguire il Browser Chrome
                  <br />
                </li>
                <li>
                  Fare click sul menù presente nella barra degli strumenti del
                  browser a fianco della finestra di inserimento url per la
                  navigazione
                  <br />
                </li>
                <li>
                  Selezionare <strong>Impostazioni</strong>
                  <br />
                </li>
                <li>
                  Fare clic su <strong>Mostra Impostazioni Avanzate</strong>
                  <br />
                </li>
                <li>
                  Nella sezione “Privacy” fare clic su bottone “
                  <strong>Impostazioni contenuti</strong>“<br />
                </li>
                <li>
                  Nella sezione “Cookie” à possibile modificare le seguenti
                  impostazioni relative ai cookie:
                  <br />
                </li>
              </ol>
              <p class="subheading">
                -&gt; Consentire il salvataggio dei dati in locale
                <br />
                -&gt; Modificare i dati locali solo fino alla chiusura del
                browser
                <br />
                -&gt; Impedire ai siti di impostare i cookie
                <br />
                -&gt; Bloccare i cookie di terze parti e i dati dei siti
                <br />
                -&gt; Gestire le eccezioni per alcuni siti internet
                <br />
                -&gt; Eliminazione di uno o tutti i cookie
                <br />
              </p>
              <p class="paragraph-43">
                Per maggiori informazioni visita la{" "}
                <a
                  href="https://support.google.com/accounts/answer/61416?hl=it"
                  target="_blank"
                >
                  pagina dedicata.
                </a>
                <br />
              </p>
              <h4>
                <strong>Mozilla Firefox</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>
                  Eseguire il Browser Firefox
                  <br />
                </li>
                <li>
                  Fare click sul menù presente nella barra degli strumenti del
                  browser a fianco della finestra di inserimento url per la
                  navigazione
                  <br />
                </li>
                <li>
                  Selezionare <strong>Opzioni</strong>
                  <br />
                </li>
                <li>
                  Seleziona il pannello <strong>Privacy</strong>
                  <br />
                </li>
                <li>
                  Fare clic su <strong>Mostra Impostazioni Avanzate</strong>
                  <br />
                </li>
                <li>
                  Nella sezione “Privacy” fare clic su bottone “
                  <strong>Impostazioni contenuti</strong>“<br />
                </li>
                <li>
                  Nella sezione “<strong>Tracciamento</strong>” è possibile
                  modificare le seguenti impostazioni relative ai cookie:
                  <br />
                </li>
              </ol>
              <p class="subheading">
                -&gt; Richiedi ai siti di non effettuare alcun tracciamento
                <br />
                -&gt; Comunica ai siti la disponibilità ad essere tracciato
                <br />
                -&gt; Non comunicare alcuna preferenza relativa al tracciamento
                dei dati personali
                <br />
              </p>
              <ol role="list" class="list-5" start="8">
                <li>
                  Eseguire il Browser Firefox
                  <br />
                </li>
              </ol>
              <p class="subheading">
                -&gt; Abilitando “Utilizza impostazioni personalizzate”
                selezionare di accettare i cookie di terze parti (sempre, dai
                siti più visitato o mai) e di conservarli per un periodo
                determinato (fino alla loro scadenza, alla chiusura di Firefox o
                di chiedere ogni volta)
                <br />
                -&gt; Rimuovere i singoli cookie immagazzinati
                <br />
              </p>
              <p class="paragraph-43">
                Per maggiori informazioni visita la{" "}
                <a
                  href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
                  target="_blank"
                >
                  pagina dedicata.
                </a>
                <br />
                <br />
              </p>
              <h4>
                <strong>Microsoft Edge</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>
                  Eseguire il Browser Microsoft Edge
                  <br />
                </li>
                <li>
                  Fare click sul pulsante <strong>. . .</strong> scegliere{" "}
                  <strong>Impostazioni</strong>
                  <br />
                </li>
                <li>
                  Nella schermata successiva scorrere fino alla voce{" "}
                  <strong>Impostazioni avanzate</strong> e cliccare su{" "}
                  <strong>Visualizza impostazioni avanzate</strong>
                  <br />
                </li>
                <li>
                  Una volta visualizzate le impostazioni avanzate, scorrere in
                  basso fino al raggiungimento della voce Cookie e segliere
                  l’impostazione desiderata:
                  <br />
                </li>
              </ol>
              <p class="subheading">
                -&gt; Blocca tutti i cookie
                <br />
                -&gt; Blocco solo i cookie di terze parti
                <br />
                -&gt; Non bloccare i cookie
                <br />
              </p>
              <p class="paragraph-43">
                Per maggiori informazioni visita la{" "}
                <a
                  href="http://windows.microsoft.com/it-it/windows-10/edge-privacy-faq"
                  target="_blank"
                >
                  pagina dedicata.
                </a>
                <br />
              </p>
              <h4>
                <strong>Microsoft Internet Explorer</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>
                  Eseguire il Browser Internet Explorer
                  <br />
                </li>
                <li>
                  Fare click sul pulsante <strong>Strumenti</strong> e scegliere{" "}
                  <strong>Opzioni Internet</strong>
                  <br />
                </li>
                <li>
                  Fare click sulla scheda <strong>Privacy</strong> e nella
                  sezione Impostazioni modificare il dispositivo di scorrimento
                  in funzione dell’azione desiderata per i cookie:
                  <br />
                </li>
              </ol>
              <p class="subheading">
                -&gt; Bloccare tutti i cookie
                <br />
                -&gt; Consentire tutti i cookie
                <br />
                -&gt; Selezione dei siti da cui ottenere cookie: spostare il
                cursore in una posizione intermedia in modo da non bloccare o
                consentire tutti i cookie, premere quindi su Siti, nella casella
                Indirizzo Sito Web inserire un sito internet e quindi premere su
                Blocca o Consenti
                <br />
              </p>
              <p class="paragraph-43">
                Per maggiori informazioni visita la{" "}
                <a
                  href="http://windows.microsoft.com/it-it/windows-10/edge-privacy-faq"
                  target="_blank"
                >
                  pagina dedicata.
                </a>
                <br />
              </p>
              <h4>
                <strong>Apple Safari 6 e/o superiore</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>
                  Eseguire il Browser Safari
                  <br />
                </li>
                <li>
                  Fare click su <strong>Safari</strong>, selezionare{" "}
                  <strong>Preferenze</strong> e premere su{" "}
                  <strong>Privacy</strong>
                  <br />
                </li>
                <li>
                  Nella sezione <em>Blocca Cookie</em> specificare come Safari
                  deve accettare i cookie dai siti internet.
                  <br />
                </li>
                <li>
                  Per visionare quali siti hanno immagazzinato i cookie cliccare
                  su <strong>Dettagli</strong>
                  <br />
                </li>
              </ol>
              <p class="paragraph-43">
                Per maggiori informazioni visita la{" "}
                <a
                  href="http://windows.microsoft.com/it-it/windows-10/edge-privacy-faq"
                  target="_blank"
                >
                  pagina dedicata.
                </a>
                <br />
              </p>
              <h4>
                <strong>Apple Safari iOS (dispositivi mobile)</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>
                  Eseguire il Browser Safari iOS
                  <br />
                </li>
                <li>
                  Tocca su <strong>Impostazioni</strong> e poi{" "}
                  <strong>Safari</strong>
                  <br />
                </li>
                <li>
                  Tocca su <strong>Blocca Cookie</strong> e scegli tra le varie
                  opzioni: “Mai”, “Di terze parti e inserzionisti” o “Sempre”
                  <br />
                </li>
                <li>
                  Per cancellare tutti i cookie immagazzinati da Safari, tocca
                  su <strong>Impostazioni</strong>, poi su{" "}
                  <strong>Safari</strong> e infine su{" "}
                  <strong>Cancella Cookie e dati</strong>
                  <br />
                </li>
              </ol>
              <p class="paragraph-43">
                Per maggiori informazioni visita la{" "}
                <a
                  href="http://windows.microsoft.com/it-it/windows-10/edge-privacy-faq"
                  target="_blank"
                >
                  pagina dedicata.
                </a>
                <br />
              </p>
              <h4>
                <strong>Opera</strong>
              </h4>
              <ol role="list" class="list-5">
                <li>
                  Eseguire il Browser Opera
                  <br />
                </li>
                <li>
                  Fare click sul Preferenze poi su Avanzate e infine su Cookie
                  <br />
                </li>
                <li>
                  Selezionare una delle seguenti opzioni:
                  <br />
                </li>
              </ol>
              <p class="subheading">
                -&gt; Accetta tutti i cookie
                <br />
                -&gt; Accetta i cookie solo dal sito che si visita: i cookie di
                terze parti e che vengono inviati da un dominio diverso da
                quello che si sta visitando verranno rifiutati
                <br />
                -&gt; Non accettare mai i cookie: tutti i cookie non verranno
                mai salvati
                <br />
              </p>
              <p class="paragraph-43">
                Per maggiori informazioni visita la{" "}
                <a
                  href="http://windows.microsoft.com/it-it/windows-10/edge-privacy-faq"
                  target="_blank"
                >
                  pagina dedicata.
                </a>
                <br />
              </p>
              <h4>
                <strong>Icone di Social Network</strong>
              </h4>
              <p class="paragraph-43">
                Sul nostro Sito è possibile trovare anche social
                buttons/widgets, ovvero particolari “pulsanti” raffiguranti le
                icone di social network (Facebook, Twitter, YouTube, Google+,
                LinkedIn, Tumblr, Pinterest, Instagram). Questi “pulsanti” ti
                consentono di raggiungere e interagire con un “click”
                direttamente con i social network. Grazie al click sui Social
                buttons/widgets il social network acquisisce i dati relativi
                alla visita dell’Utente. Al di fuori di questi casi, in cui è
                l’Utente spontaneamente a voler condividere con il click i
                propri dati di navigazione con i social network prescelti,{" "}
                <strong>Rankit Media Hub SRL</strong> non condivide alcuna
                informazione di navigazione o dati degli Utenti acquisiti
                attraverso i Siti con i social network accessibili grazie ai
                Social buttons/widgets. Per ulteriori informazioni è possibile
                consultare la politica sui termini &amp; privacy dei singoli
                social network acceduti attraverso il nostro sito cliccando sui
                link in basso.
                <br />
              </p>
              <h4>
                <strong>
                  Politica termini &amp; privacy dei servizi di terze parti
                </strong>
              </h4>
              <ul role="list" class="list-5">
                <li>
                  <a
                    href="https://www.google.it/intl/it/policies/privacy"
                    target="_blank"
                  >
                    Servizi di Google (Search, Gmail, Google+, Maps, Drive,
                    Analytics, Adword, Adsense, ecc..)
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://support.google.com/youtube/answer/2801895?hl=it"
                    target="_blank"
                  >
                    YouTube
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/help/cookies?ref_type=sitefooter"
                    target="_blank"
                  >
                    Facebook
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://support.twitter.com/articles/20170519-uso-dei-cookie-e-di-altre-tecnologie-simili-da-parte-di-twitter"
                    target="_blank"
                  >
                    Twitter
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/legal/cookie_policy"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/about/legal/privacy"
                    target="_blank"
                  >
                    Instagram
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://www.tumblr.com/policy/en/privacy"
                    target="_blank"
                  >
                    Tumblr
                  </a>
                  <br />
                </li>
                <li>
                  <a
                    href="https://www.tumblr.com/policy/en/privacy"
                    target="_blank"
                  >
                    Pinterest
                  </a>
                  <br />
                </li>
              </ul>
              <p class="paragraph-43">
                Questa pagina è visibile, mediante link in calce in tutte le
                pagine del Sito.
                <br />
              </p>
              {/*<h1 class="heading-75"><strong class="bold-text-2">Aggiornamento informativa<br/></strong><br/></h1>*/}
              <p>
                ‍<strong>Data ultimo aggiornamento:</strong> 11 Dicembre 2024
                <br />
              </p>
              <p class="paragraph-43">
                Rankit Media Hub SRL
                <br />
                Viale Liguria 24, Milano 20143 - C.F e P.I 13836910961
                <br />
                <a href="mailto:info(@)rankitmediahub.com">
                  Info(@)rankitmediahub.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ItCookiePolicy;
